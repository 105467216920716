<template>
  <div>
    <v-dialog
      v-model="dialog"
      v-if="$store.getters['auth/isLoggedIn']"
      width="600"
    >
      <template v-slot:activator="{}">
        <v-btn
          small
          outlined
          @click="open"
          :disabled="appointmentDates.sesion_price == 0"
          class="ml-2"
        >
          {{ $t("pay") }}
        </v-btn>
      </template>

      <v-card id="select_token_payment">
        <v-card-title>
          {{ $t("pay_token_payment") }}
        </v-card-title>

        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row
            v-if="!user_id && $store.getters['auth/isStudio']"
            justify="center"
          >
            <v-col md="4">
              <base-border-card
                :title="$tc('studio')"
                @card_clicked="user_id = appointment.studio_id"
              ></base-border-card>
            </v-col>
            <v-col md="4">
              <base-border-card
                :title="$tc('artist')"
                @card_clicked="user_id = appointment.tattooer_id"
              ></base-border-card>
            </v-col>
          </v-row>

          <v-row v-else-if="online" justify="center">
            <v-col md="4">
              <base-border-card
                :title="$t('expenses.stripe')"
                @card_clicked="link_dialog = true"
                :disabled="
                  !hasOnline ||
                  ($store.getters['auth/isStudio'] &&
                    user_id !== appointment.studio_id)
                "
              >
              </base-border-card>
            </v-col>
            <v-col md="4">
              <base-border-card
                :title="$t('expenses.other')"
                @card_clicked="pay(pm)"
                :disabled="!hasOnline"
              >
              </base-border-card>
            </v-col>
          </v-row>
          <template v-else>
            <v-row v-if="total == null" justify="center">
              <v-col
                cols="8"
                md="6"
                v-for="(pm, i) in payment_methods"
                :key="i"
              >
                <base-border-card
                  :title="
                    pm == 'total'
                      ? 'TOTAL ' + faltaPagar() + '€'
                      : $t('expenses.' + pm)
                  "
                  @card_clicked="pay(pm)"
                  :disabled="
                    pm === 'online' &&
                    $store.getters['auth/getSetting']('pm_stripe_id') === 0
                  "
                  :two_lines="true"
                ></base-border-card>
              </v-col>
            </v-row>
            <div class="back" v-if="total != null">
              <v-btn icon @click="total = null" small>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </div>

            <v-row v-if="total != null" justify="center">
              <v-col cols="8" md="4" v-for="(pm, i) in total_methods" :key="i">
                <base-border-card
                  :title="$t('expenses.' + pm)"
                  @card_clicked="pay(pm)"
                  :disabled="
                    pm === 'online' &&
                    $store.getters['auth/getSetting']('pm_stripe_id') === 0
                  "
                  :two_lines="$vuetify.breakpoint.mdAndUp"
                ></base-border-card>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-dialog
          width="400"
          v-model="link_dialog"
          v-if="$store.getters['auth/isLoggedIn']"
        >
          <v-card id="consentFormLink">
            <v-card-title
              style="text-decoration: underline; text-transform: uppercase"
            >
              Vista del cliente:
            </v-card-title>
            <div class="close">
              <v-btn icon @click="link_dialog = false">
                <v-icon id="iconClose">mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text class="pb-2">
              <v-text-field outlined dense id="URL" readonly v-model="URL">
                <template slot="prepend-inner">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon class="mr-2" v-on="on" @click="copy()">
                        mdi-content-copy
                      </v-icon>
                    </template>
                    {{ $t("copy") }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer />
              <v-btn
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                height="30px"
                elevation="0"
                color="primary"
                @click="goToPay"
              >
                {{ $t("to_complete") }}
              </v-btn>
              <v-btn
                type="submit"
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                @click="openSendOnline()"
                elevation="0"
              >
                <v-icon style="margin-right: 5px" size="14px">$send</v-icon>
                {{ $t("enviar", { name: $t("") }) }}
              </v-btn>
              <SendMail
                ref="sendMail"
                hidden
                :appointment="appointment"
                :appointmentDates="appointmentDates"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>

    <NewPartialPayment
      :appointment="appointment"
      :appointmentDates="appointmentDates"
      :user_id="user_id"
      ref="new_partial_payment"
      @update="
        dialog = false;
        $emit('update');
        $emit('close');
      "
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      link_dialog: false,
      payment_methods: ["total", "partial"],
      total_methods: ["cash", "card", "online"],
      total: null,
      user_id: null,
      online: false,
    };
  },
  components: {
    NewPartialPayment: () =>
      import("@/components/appointments/sessions/NewPartialPayment"),
    SendMail: () => import("@/components/mails/SendMail"),
  },

  props: ["appointment", "appointmentDates", "totalTokenPayment"],
  computed: {
    URL() {
      return `${window.location.origin}/?#/inscription/studio/${this.appointment.studio.token}/appointment/${this.appointment.id}/sesion/${this.appointmentDates.id}`;
    },
    hasOnline() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "pm_stripe_id"
      );
      if (s) return s.value !== 0;
      return false;
    },
  },
  methods: {
    ...mapActions("appointments", ["generatePendingCustomer"]),
    ...mapActions("expenses", ["confirmExpense", "editExpense"]),
    pay(pm) {
      if (pm === "total") {
        this.total = pm;
      } else if (pm === "online" && !this.online) {
        this.online = true;
      } else if (pm === "partial") {
        console.log("PARTIAL");
        this.$refs.new_partial_payment.dialog = true;
      } else {
        console.log("OTHERS");
        console.log("ID", this.$store.state.auth.user.id);
        let a = this.appointmentDates.expenses.find(
          (x) =>
            !x.conceptos.startsWith("Comision artista") &&
            x.user_id == this.appointment.studio_id
        );
        a.pay_mode = pm;
        a.user_id = this.user_id;
        this.editExpense(a).then((data) => {
          this.confirmExpense(a.id).then(() => {
            this.$emit("update");
            this.dialog = false;
            this.$alert(this.$t("save_ok"), "", "");
          });
        });
      }
    },
    open() {
      this.dialog = true;
      this.user_id = null;
      this.online = false;
      this.total = null;
    },

    openSendOnline() {
      console.log("OPEN SEND ONLINE");
      this.$refs.sendMail.open("ONLINE");
    },

    faltaPagar() {
      console.log("FALTA PAGAR");
      let resto = 0;
      if (this.totalTokenPayment) {
        console.log("HAY TOKENS", this.totalTokenPayment);
        if (this.appointmentDates)
          resto =
            this.appointmentDates.sesion_price -
            this.appointmentDates.alreadyPayed -
            this.totalTokenPayment;
      } else {
        console.log(" NO HAY TOKENS");
        resto =
          this.appointmentDates.sesion_price -
          this.appointmentDates.alreadyPayed;
      }
      console.log("RESTO", resto);
      return resto;
    },

    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.link_dialog = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    goToPay() {
      console.log("GOTOPAY");
      this.$router.push({
        name: "customer_online_payment",
        params: {
          type: this.appointment.studio.token,
          appointment_id: this.appointment.id,
          appointment_date_id: this.appointmentDates.id,
        },
      });
    },
  },
};
</script>

<style lang="sass"></style>
