var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters['auth/isLoggedIn'])?_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","outlined":"","disabled":_vm.appointmentDates.sesion_price == 0},on:{"click":_vm.open}},[_vm._v(" "+_vm._s(_vm.$t("pay"))+" ")])]}}],null,false,3579393975),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"id":"select_token_payment"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("pay_token_payment"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(!_vm.user_id && _vm.$store.getters['auth/isStudio'])?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$tc('studio')},on:{"card_clicked":function($event){_vm.user_id = _vm.appointment.studio_id}}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$tc('artist')},on:{"card_clicked":function($event){_vm.user_id = _vm.appointment.tattooer_id}}})],1)],1):(_vm.online)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$t('expenses.stripe'),"disabled":!_vm.hasOnline ||
                (_vm.$store.getters['auth/isStudio'] &&
                  _vm.user_id !== _vm.appointment.studio_id)},on:{"card_clicked":function($event){_vm.link_dialog = true}}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$t('expenses.other'),"disabled":!_vm.hasOnline},on:{"card_clicked":function($event){return _vm.pay(_vm.pm)}}})],1)],1):[(_vm.total == null)?_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.payment_methods),function(pm,i){return _c('v-col',{key:i,attrs:{"cols":"8","md":"6"}},[_c('base-border-card',{attrs:{"title":pm == 'total'
                    ? 'TOTAL ' + _vm.faltaPagar() + '€'
                    : _vm.$t('expenses.' + pm),"disabled":pm === 'online' &&
                  _vm.$store.getters['auth/getSetting']('pm_stripe_id') === 0,"two_lines":true},on:{"card_clicked":function($event){return _vm.pay(pm)}}})],1)}),1):_vm._e(),(_vm.total != null)?_c('div',{staticClass:"back"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.total = null}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1):_vm._e(),(_vm.total != null)?_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.total_methods),function(pm,i){return _c('v-col',{key:i,attrs:{"cols":"8","md":"4"}},[_c('base-border-card',{attrs:{"title":_vm.$t('expenses.' + pm),"disabled":pm === 'online' &&
                  _vm.$store.getters['auth/getSetting']('pm_stripe_id') === 0,"two_lines":_vm.$vuetify.breakpoint.mdAndUp},on:{"card_clicked":function($event){return _vm.pay(pm)}}})],1)}),1):_vm._e()]],2),(_vm.$store.getters['auth/isLoggedIn'])?_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.link_dialog),callback:function ($$v) {_vm.link_dialog=$$v},expression:"link_dialog"}},[_c('v-card',{attrs:{"id":"consentFormLink"}},[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"}},[_vm._v(" Vista del cliente: ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.link_dialog = false}}},[_c('v-icon',{attrs:{"id":"iconClose"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pb-2"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","id":"URL","readonly":""},model:{value:(_vm.URL),callback:function ($$v) {_vm.URL=$$v},expression:"URL"}},[_c('template',{slot:"prepend-inner"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.copy()}}},on),[_vm._v(" mdi-content-copy ")])]}}],null,false,2496974216)},[_vm._v(" "+_vm._s(_vm.$t("copy"))+" ")])],1)],2)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{staticStyle:{"width":"100px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"height":"30px","elevation":"0","color":"primary"},on:{"click":_vm.goToPay}},[_vm._v(" "+_vm._s(_vm.$t("to_complete"))+" ")]),_c('v-btn',{staticStyle:{"width":"100px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"type":"submit","elevation":"0"},on:{"click":function($event){return _vm.openSendOnline()}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$send")]),_vm._v(" "+_vm._s(_vm.$t("enviar", { name: _vm.$t("") }))+" ")],1),_c('SendMail',{ref:"sendMail",attrs:{"hidden":"","appointment":_vm.appointment,"appointmentDates":_vm.appointmentDates}})],1)],1)],1):_vm._e()],1)],1):_vm._e(),_c('NewPartialPayment',{ref:"new_partial_payment",attrs:{"appointment":_vm.appointment,"appointmentDates":_vm.appointmentDates,"user_id":_vm.user_id},on:{"update":function($event){_vm.dialog = false;
      _vm.$emit('update');
      _vm.$emit('close');}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }